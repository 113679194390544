import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/ofertowanie-crm-tlo.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    dokument: file(relativePath: { eq: "dokument-ofertowy-crm-questy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    widgety: file(relativePath: { eq: "crm-widgety-raporty.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
       oferta: file(relativePath: { eq: "wzor-oferty-questy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Zarzadzaniekampaniamicrm = ({ data }) => {
  return (
    <Artykul
      title="Ofertowanie w CRM: jak zarządzać tym procesem?"
      keywords={["ofertowanie crm, program do ofertowania"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Ofertowanie w CRM: jak zarządzać tym procesem?"
      metaTitle="Ofertowanie w CRM: jak zarządzać tym procesem?"
      metaDescription="System CRM służy również jako program do ofert. Jak usprawnia proces ofertowania? ✅ Ofertowanie CRM • Program do ofertowania • Video 2020"
    >
      <p style={{
          paddingTop: 25,
          paddingBottom: 25,
        }}>
        W najnowszej odsłonie naszego vloga “System CRM w zarządzaniu” opowiemy Wam kilka słów o jednym z&nbsp;etapów procesu sprzedaży, 
        jakim jest ofertowanie. Z naszej perspektywy krok ten jest kluczowy przy podejmowaniu decyzji przez potencjalnego klienta i w dużej 
        mierze decyduje o tym, czy skorzysta on z naszej oferty usług czy też raczej wybierze się do konkurencji. Dlatego w&nbsp;pierwszej 
        kolejności najpierw omówimy sobie najczęstsze problemy występujące w procesie tworzenia ofert a następnie 
        zastanowimy się,<strong> jak&nbsp;wykorzystać CRM jako program do tworzenia ofert handlowych</strong>.
      </p>
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/a_KLMaAYdRc"
        allowFullScreen
        ratio="16:9"
      />
      <h2 style={{
          paddingTop: 25,
          paddingBottom: 15,
        }}>Najczęstsze problemy w&nbsp;procesie ofertowania</h2>
      <p>
        <strong>
         Brak dostępu do wszystkich ofert przygotowywanych przez dział sprzedaży. </strong>
        Często chcąc szybko przygotować ofertę handlową zapisujemy ją jedynie na dysku swojego komputera 
        lub, jeżeli tylko o tym pamiętamy, przesyłamy ją na dysk współdzielony. Skutkiem tego 
        handlowcy nie zawsze mają prosty i szybki dostęp do wszystkich przygotowanych dla danego klienta ofert.</p>

      <p>
        <strong>Każdy handlowiec ma inny wzór oferty handlowej. </strong> 
        Jeśli przygotowujemy oferty danym klientom w Wordzie, Excelu albo innym podobnym 
        narzędziu, to każdy z handlowców może wprowadzać swoje modyfikacje do szablonu 
        oferty. Powoduje to, że każda oferta tak naprawdę może się od siebie różnić, a&nbsp;dokumentom 
        wystawianym przez nasze przedsiębiorstwo brakuje spójności. Tym samym różni się także proces obsługi klienta.
      </p>

      <p>
        <strong>Dublowanie ofert.</strong> Czasami zdarza się tak, że&nbsp;jeden klient z różnych względów 
        jest obsługiwany przez kilku handlowców. Jeśli nie mamy spójnej i dostępnej dla wszystkich 
        bazy ofert, może zdarzyć się, że podczas ofertowania przedstawimy mu dwie oferty na zupełnie różnych warunkach.
      </p>

      <p>
        <strong>Przygotowywanie oferty trwa za długo. </strong> 
        Pracownicy działu handlowego często skarżą się, że zajmuje im to za dużo czasu, który mogliby 
        poświęcić na ważniejsze zadania, takie jak budowanie relacji z kontrahentami.
      </p>

      <p>
        <strong>Bierność po wysłaniu oferty.</strong> Zdarza się tak, że handlowcy 
        dokonują wysyłki ofert mailem do nowych klientów i następnie biernie czekają 
        na jej zaakceptowanie. A tak naprawdę nie mamy przecież pewności, że dokument 
        oferty w ogóle trafił do klienta i czy na pewno spełnia wszystkie jego 
        oczekiwania. Poprzestanie na przesłaniu klientowi oferty mailem to ogromny błąd.
      </p>

      <p>
        <strong>Niewysłanie oferty.</strong> Czasami niestety też zapominamy o dostarczaniu 
        ofert klientom. W&nbsp;najlepszym przypadku, jeśli sami się przypomną, ryzykujemy utratą 
        wizerunku i&nbsp;zmniejszamy prawdopodobieństwo sukcesu. Jeśli zaś nie przypomną 
        nam o tym, szansa na sukces spada niemal do zera i bardzo prawdopodobne 
        będzie odrzucenie oferty, jeszcze zanim dotrze ona do potencjalnego kontrahenta.
      </p>

      <p>
        <strong>Wysłanie nieaktualnej oferty.</strong> W przypadku, gdy w naszym 
        portfolio produktów lub usług znajduje się bardzo wiele pozycji, możliwa 
        jest sytuacja, że zaproponujemy danym klientom pozycje, których nie ma już w katalogu 
        lub są opisane innymi zmiennymi. Akceptacja oferty przygotowanej na podstawie 
        nieaktualnych danych może przyczynić się do napięć i wpłynąć negatywnie na relację z kontrahentem.
      </p>

      <p>
        <strong>Brak wiedzy na temat ilości i wartości ofert.</strong> Ostatnim bardzo istotnym 
        punktem są elementy związane z&nbsp;raportowaniem. Managerowie lub członkowie zarządu często 
        nie mają szczegółowych statystyk dotyczących danych ofert handlowych – ich ilości, 
        wartości czy też informacje o tym, jakie kategorie produktowe są oferowane najczęściej. 
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.dokument]}
        alts={["Dokument ofertowy w systemie CRM"]}
      />
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz poprawić proces ofertowania w Twojej dużej, średniej lub małej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;wprowadzić ofertowanie na wyższy poziom
            </Link>
          </div>
          </div>
    </section>
      <h2 style={{
          paddingTop: 25,
          paddingBottom: 15,
        }}>Moduł CRM ofertowanie</h2>
      <p>
        Jak uniknąć powyższych problemów i&nbsp;jednocześnie udoskonalić swoje ofertowanie? 
        CRM okazuje się w tym bardzo pomocny. Omówimy teraz te z  funkcjonalności systemu 
        (moduł CRM ofertowanie), dzięki którym możliwe jest usprawnienie, uporządkowanie 
        oraz przyspieszenie procesu przygotowywania ofert.
      </p>

       <h3 style={{
        textAlign: "left",
          paddingTop: 25,
          paddingBottom: 15,
        }}>Szablony ofert pozwalają na spójność</h3>

      <p>
       Moduł ofertowania w programie CRM posiada jedną, wspólną bazę ofert – jest ona dostępna 
       zarówno na liście wszystkich ofert jak i&nbsp;w&nbsp;kartotece danego kontrahenta w&nbsp;odpowiedniej sekcji.
      </p>

      <p>
        Przygotowanie dokumentu z wykorzystaniem wzoru oferty handlowej dostarczanej przez 
        CRM system ofertowania klientów składa się tak naprawdę z kilku szybkich kroków:
      </p>

      <p>
        <ul>
          <li>wyboru kontrahenta,</li>
          <li>
            zdefiniowania parametrów takich jak forma płatności lub rodzaj
            dostawy,
          </li>
          <li>wybór pozycji oferty i zdefiniowanie cen,</li>
          <li>
            wygenerowanie dokumentu w formacie PDF lub bezpośrednie wysłanie go drogą mailową.
          </li>
        </ul>
      </p>

      <p style={{
          paddingBottom: 15,
        }}>
        Przekształcanie zaakceptowanej oferty w&nbsp;zamówienie jest proste, zajmuje mało czasu i&nbsp;eliminuje możliwość popełnienia błędu 
        przy przepisywaniu danych. Praca w programie do tworzenia ofert przekłada się także na&nbsp;efektywną obsługę klientów.
      </p>

      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.oferta]}
        alts={["szablon oferty w crm"]}
      />

       <h3 style={{
        textAlign: "left",
          paddingTop: 20,
          paddingBottom: 15,
        }}>Zadania powiązane z ofertami tworzonymi w CRM</h3>

      <p>
       Dzięki systemowi CRM handlowcy mogą samodzielnie planować sobie zadania względem nowej 
       oferty – na&nbsp;przykład telefonicznego omówienia jej z klientem. Możemy zadbać też o&nbsp;to, 
       aby system po zapisaniu takiego dokumentu automatycznie utworzył handlowcowi zadanie, 
       które pozwoli zadbać o nieodrzucenie oferty. W&nbsp;takim przypadku musielibyśmy indywidualnie 
       ustalić, jak taki mechanizm ma działać – moduł CRM ofertowanie jest narzędziem 
       elastycznym i&nbsp;pozwala tutaj na zastosowanie różnych podejść.
      </p>

      <h3 style={{
        textAlign: "left",
          paddingTop: 20,
          paddingBottom: 15,
        }}>Raporty dotyczące przygotowanych w systemie CRM ofert</h3>

      <p>
        Teraz kwestia wiedzy kierownika na temat dokumentów ofert przygotowanych przez zespół do 
        spraw obsługi klienta. Dzięki systemowi CRM tworzenie raportów i zestawień następuje w sposób 
        automatyczny, a manager może w łatwy i&nbsp;szybki sposób przejrzeć dane i statystyki dotyczące procesu 
        ofertowania – na przykład ilości dostarczanych ofert klientom, ich wartości czy też grup 
        produktowych, które są proponowane klientom.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.widgety]}
        alts={["raport CRM dotyczący ofert"]}
      />
      <p>
        Dzięki tym prostym funkcjom system zarządzania relacjami z klientami może 
        wspomóc proces składania ofert i&nbsp;pozwolić realizować efektywną obsługę klientów.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz poprawić proces ofertowania w Twojej dużej, średniej lub małej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM</p>
      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;wprowadzić ofertowanie na wyższy poziom
            </Link>
          </div>
          </div>
    </section>

    <h2 style={{
          paddingTop: 25,
          paddingBottom: 15,
        }}>Proces ofertowania w CRM – podsumowanie</h2>

      <p>
        Dzięki CRM tworzenie ofert staje się przyjemnością. Program do tworzenia ofert sprawia, że łatwiejsza 
        staje się organizacja systemu pracy handlowców. Dzięki niemu nie przystaną oni tylko na przesłaniu 
        klientowi oferty i będą  pamiętać o dalszych krokach koniecznych do realizacji. Ty jako osoba 
        zarządzająca będziesz mieć możliwość przygotować szablon oferty - spójnej i dostępnej dla wszystkich 
        zainteresowanych pracowników. Zadbasz w ten sposób o wizerunek swojej dużej, średniej lub małej firmy.
      </p>
      <p>
        Oczywiście, CRM to coś więcej niż tylko program do generowania ofert. 
        Więcej funkcji i&nbsp;zastosowań tego rozwiązania znajdziesz, między innymi, tutaj:
      </p>

      <p>
        <ul>
          <li>
            <Link to="/wdrazanie-kultury-pracy-CRM">
              Wdrażanie kultury pracy z CRM w organizacji
            </Link>
          </li>
          <li>
            <Link to="/zarzadzanie-zespolem-marketingu-crm">
              Zarządzanie pracą zespołu marketingu w&nbsp;systemie CRM
            </Link>
          </li>
          <li>
            <Link to="/lejek-sprzedazy-crm">
              Lejek sprzedaży – wsparcie dla działu handlowego
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Zarzadzaniekampaniamicrm;
